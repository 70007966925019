$(document).on("turbolinks:load", function () {
  // console.log("[table_olditem]");

  if ($('#with_old').length) {
    // console.log("#with_old checked = %o", $('#with_old').prop("checked"));
    if ($('#with_old').prop("checked") == true) {
      show_old_rows();
    }
    else {
      hide_old_rows();
    }
  }

  $('#with_old').on("click", function() {
    if ($(this).prop("checked") == true) {
      show_old_rows();
    }
    else {
      hide_old_rows();
    }
  })

  function hide_old_rows () {
    // console.log("hide_old_rows");
    $('tr.olditem').each(function() {
      $(this).hide();
    })
  }
  function show_old_rows () {
    // console.log("show_old_rows");
    $('tr.olditem').each(function() {
      $(this).css({'display':'table-row'});
    })
  }

  if ($('#with_others').length) {
    // console.log("#with_others checked = %o", $('#with_others').prop("checked"));
    if ($('#with_others').prop("checked") == true) {
      hide_others_rows();
    }
    else {
      show_others_rows();
    }
  }

  $('#with_others').on("click", function() {
    if ($(this).prop("checked") == true) {
      hide_others_rows();
    }
    else {
      show_others_rows();
    }
  })

  function hide_others_rows () {
    // console.log("hide_others_rows");
    $('tr.others').each(function() {
      $(this).hide();
    })
  }
  function show_others_rows () {
    // console.log("show_others_rows");
    $('tr.others').each(function() {
      $(this).css({'display':'table-row'});
    })
  }
});
