require("./s_scroll");
require("./contract");
require("./quotation");
require("./tab");
require("./table_olditem");
// require("./dailyreport");
require("./search_customer");
// require("./categories");
// require("./search_product");
// require("./dailyreport_product_categories");
require("./section_member");
//require("./journal");
require("./updown_quotation_content_list");

$(document).on("keydown", "input", function(e) {
  if ((e.which && e.which === 13) || (e.keyCode && e.keycode === 13)) {
    return false;
  }
  else {
    return true;
  }
});