$(document).on("click", ".quotationContentUpList, .quotationContentDownList", function () {
  updown_qiotation_content_list($(this));
});

function updown_qiotation_content_list(updownButton) {
  // ボタンのクラスで、"up" か "down" か
  // console.log(updownButton);
  var direction;
  if (updownButton.hasClass("quotationContentUpList")) {
    direction = "up"
  }
  else {
    direction = "down"
  }
  // console.log(direction);

  // 上下ボタンが押された行
  var this_tr = updownButton.parents('.quotation_content_class');
  // console.log(this_tr);

  // 入れ替え対象の行
  var target_tr;
  if (direction == "up") {
    target_tr = this_tr.prev('tr'); // 上ボタンがクリックされた上の行
  }
  else {
    target_tr = this_tr.next('tr'); // 下ボタンがクリックされた下の行
  }
  // console.log(target_tr);

  // 対象の行に上下ボタンが無ければ入れ替えを行わず終了(行追加ボタンの行、値引き行)
  var target_tr_up_button = target_tr.find('.quotationContentUpList');
  if (target_tr_up_button.length == 0) {
    // console.log("cannot up/down!");
    return;
  }

  // 行番号の値を入れ替える
  var this_number = this_tr.find($('[id$="_行番号"]'));
  // console.log(this_number.val());
  var target_number = target_tr.find($('[id$="_行番号"]'))
  // console.log(target_number.val());
  var new_this_number = target_number.val();
  var new_target_number = this_number.val();
  this_number.val(new_this_number);
  target_number.val(new_target_number);

  // 行を入れ替える
  if (direction == "up") {
    this_tr.insertBefore(target_tr);
  }
  else {
    this_tr.insertAfter(target_tr);
  }
}
