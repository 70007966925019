$(document).on("turbolinks:load", function () {
  // $('[id$="_コメントチェック"]').each(function () {
  //   var self_id = $(this).attr("id");
  //   var content_id = self_id.slice(0, self_id.lastIndexOf("_"));
  //   enableDisableQuotationContent(content_id, self_id);
  // });
  $('[id*="_数量"]').each(function () {
    calcQuotationContentTotalPrice($(this).attr("id"));
  });
  $('[id$="_content_type"]').each(function () {
    var self_id = $(this).attr("id");
    var content_id = self_id.slice(0, self_id.indexOf("_content_type"));
    disableQuotationContentOnSummary(content_id);
  });
});

$(document).on("change", '[id$="_非計上チェック"]', function() {
  var self_id = $(this).attr("id");
  var quotation_detail_id = self_id.slice(0, self_id.indexOf("_非計上チェック"));
  var my_quotation_detail_title_id = quotation_detail_id + "_見積明細書タイトル";
  // 表紙の要約行を探す
  var toppage_summary_id = searchTopPageSummaryContent(my_quotation_detail_title_id);
  // console.log('[not_sumup check] toppage_summary_id = %o', toppage_summary_id);
  // 表紙に要約行があれば表紙の金額の再計算
  if (toppage_summary_id != "") {
    setTopPageSummaryContent(toppage_summary_id, quotation_detail_id);
  }
  // 見積書全体の金額の再計算
  calcQuotationPrice();
});
$(document).on("change", '[id$="_税込区分"]', calcQuotationPrice);
$(document).on("change", '[id$="_リース料率"]', calcQuotationPrice);

$(document).on("change", '[id*="quotation_contents_attributes"]',
  function(event) {
    calcQuotationContentTotalPrice($(event.target).attr("id"));
  });
$(document).on("change", '[id$="__destroy"]', function (event) {
  var self_id = $(this).attr("id");
  if (!(self_id.match(/_quotation_contents_attributes/))) {
    // console.log("[delete detail] id = %o", self_id);
    var confirmation = confirm("削除します。よろしいですか？");
    if (!confirmation) {
      $('#' + self_id).prop("checked", false);
      return;
    }
    else {
      var my_quotation_detail_id = self_id.slice(0, self_id.lastIndexOf("__"));
      // (1) 削除する見積明細書の金額小計などをクリアする
      clearQuotationDetail(my_quotation_detail_id);

      // (2) 表紙に対応行があれば削除し、表紙の見積明細書を再計算
      var my_quotation_detail_title_id = my_quotation_detail_id + "_見積明細書タイトル";
      var toppage_summary_id = searchTopPageSummaryContent(my_quotation_detail_title_id);
      // console.log('[delete detail] toppage_summary_id = %o', toppage_summary_id);
      if (toppage_summary_id != "") {
        clearTopPageSummaryContent(toppage_summary_id, my_quotation_detail_id);
        calcQuotationDetailPrice(toppage_summary_id)
      }

      // (3) active なタブを、見積明細書の表紙のタブに変更
      var $my_div = $(this).closest("div");
      var my_id = $my_div.attr("id");
      // console.log("[delete detail] my_id = %o", my_id);
      $('.tab li').removeClass("active");
      $('.tab-area').removeClass("is-active");
      $('.tab li').find('a').each(function() {
        var idName = $(this).attr('href');
        // console.log('[delete detail] idName = %o', idName);
        var parentElm = $(this).parent();
        if (idName == "#details_0") { // 最初の見積明細書のタブ
          // console.log('[delete detail] first quotation_detail %o', idName);
          $(parentElm).addClass("active");
          $("#details_0").addClass("is-active"); // 最初の見積明細書の内容
        }
        else if (idName == ("#" + my_id)) { // 削除するタブ
          // console.log('[delete detail] deleted quotation_detail %o', idName);
          $(parentElm).hide();
        }
      });

      // (4) 見積書全体の金額の再計算
      calcQuotationPrice();
    }
  }
});

$(document).on("cocoon:before-insert", function (event, insertedItem, originalEvent) {
  // console.log('[cocoon:before-insert] originalEvent.currentTarget.id = %o', originalEvent.currentTarget.id);
  if (originalEvent.currentTarget.id == "quotation_add_summary_button") {
    // console.log('[cocoon:before-insert] Add Summary Button');
    // 既に対応行を必要数作成済みならメッセージを表示して処理をキャンセル
    //     number_of_summaries >= number_of_details なら確認メッセージ
    var diff_s_d = summaryContentsMinusDetails();
    // console.log('[cocoon:before-insert] diff_summary_detail = %o', diff_s_d);  
    if (diff_s_d >= 0) {
      // console.log('[cocoon:before-insert] enough summary -> cancel')
      alert("明細書の数を超えて対応行を追加できません。");
      event.preventDefault();
    }
  }
  else if (originalEvent.currentTarget.id == "quotation_add_discount_button") {
    // console.log('[cocoon:before-insert] Add Discount Button');
    // 既に値引行を作成済みならメッセージを表示してキャンセル
    var $my_div = $(originalEvent.currentTarget).closest("div");
    // console.log('[cocoon:before-insert] my_div = %o', $my_div);
    $my_div.find('[id$="_content_type"]').each(function () {
      // console.log('[cocoon:before-insert] %o, _content_type = %o', $(this), $(this).val());
      if ($(this).val() == 1) {
        // console.log('[cocoon:before-insert] find discount content!');
        alert("値引き行を複数作成することはできません。");
        event.preventDefault();
        return false;
      }
    });
  }
});
$(document).on("cocoon:after-insert", function (event, added_obj) {
  insertQuotation(added_obj);
});

$(document).on("focusin", "[id$='_見積明細書タイトル']", function() {
  $(this).data("previous_val", $(this).val());
})
$(document).on("change", "[id$='_見積明細書タイトル']", function (event) {
  reviseTabName(event.target);
});

$(document).on("click", "#save_quotation_button", function() {
  // 要約行が不足していたらメッセージを表示して確認
  //   number_of_summaries < number_of_details ならメッセージ表示
  var diff_s_d = summaryContentsMinusDetails();
  // console.log('[save_quotation_button] diff_summary_detail = %o', diff_s_d);  
  if (diff_s_d < 0) {
    // console.log('[save_quotation_button] not enough summary -> message')
    var confirmation = confirm("明細書の対応行が不足していますが、そのまま保存しますか？");
    if (confirmation === true) {
      $(this).submit();
    }
    else {
      return false;
    }
  }
  else {
    $(this).submit();
  }
});

////////////////////////////////////////////////////////////////

function calcQuotationContentTotalPrice(self_id) {
  // 見積内容の金額を計算する
  // self_id : 変更があった要素の id
  // console.log('[calcQuotationContentTotalPrice]');
  // console.log('self_id = %o', self_id);

  var content_id = self_id.slice(0, self_id.lastIndexOf("_"));
  var quantity_id       = content_id + "_数量";
  var unit_price_id     = content_id + "_単価";
  var total_price_id    = content_id + "_金額";
  var purchase_unit_id  = content_id + "_仕入単価";
  var purchase_total_id = content_id + "_仕入額";
  var sales_unit_id     = content_id + "_提供単価";
  var sales_total_id    = content_id + "_提供額";
  if (self_id.match(/仕入単価/)) {
    $('#' + purchase_total_id).val($('#' + quantity_id).val() * $('#' + purchase_unit_id).val());
  }
  else if (self_id.match(/提供単価/)) {
    $("#" + sales_total_id).val($("#" + quantity_id).val() * $("#" + sales_unit_id).val());
  }
  else if (self_id.match(/数量/)) {
    $('#' + total_price_id).val($('#' + quantity_id).val() * $('#' + unit_price_id).val());
    $('#' + purchase_total_id).val($('#' + quantity_id).val() * $('#' + purchase_unit_id).val());
    $("#" + sales_total_id).val($("#" + quantity_id).val() * $("#" + sales_unit_id).val());
  }
  else if (self_id.match(/単価/)) {
    $('#' + total_price_id).val($('#' + quantity_id).val() * $('#' + unit_price_id).val());
  }
  else if (self_id.match(/コメントチェック/)) {
    // enableDisableQuotationContent(content_id, self_id);
  }
  else if (self_id.match(/品名/)) {
    // var hinmei_length = $('#' + self_id).val().length;
    var hinmei_length = textLength($('#' + self_id).val());
    // console.log("hinmei_length = %o", hinmei_length);
    if (hinmei_length > 36) {
      alert("品名は全角18文字(半角36文字)以内で入力してください。\n現在、半角換算で " + hinmei_length + " 文字入力されています。")
      $('#' + self_id).focus();
      $('#' + self_id).get(0).setSelectionRange(hinmei_length, hinmei_length);
      return;
    };
    var commnt_id = content_id + "_コメントチェック";
    if ($("#" + self_id).val().match(/■|以下/)) {
      $("#" + commnt_id).prop("checked", true);
    }
    else {
      $("#" + commnt_id).prop("checked", false);
    }
    // enableDisableQuotationContent(content_id, commnt_id);
  }
  else if (self_id.match(/__destroy/)){
    var confirmation = confirm("削除します。よろしいですか？");
    if (!confirmation) {
      $('#' + self_id).prop("checked", false);
      return;
    }
    else {
      clearQuotationContent(content_id.slice(0, content_id.lastIndexOf("_")));
      // enableDisableQuotationContent(content_id.slice(0, content_id.lastIndexOf("_")), self_id);
      var $content_tr = $('#' + self_id).parents("tr");
      $content_tr.hide();
    }
  }
  else {
    return;
  }
  // 値引き行の再計算
  var quotation_detail_id
    = self_id.slice(0, self_id.indexOf("quotation_contents_attributes"));
  $('[id$="_品名"]').each(function () {
    // 同じ見積明細書の範囲内で
    if ($(this).attr("id").match(quotation_detail_id)) {
      checkDiscount($(this).attr("id"));
    }
  });
  // 見積明細書の金額を計算
  calcQuotationDetailPrice(self_id);
};

function textLength(text) {
  // 半角、全角を考慮した文字数のカウント(半角:1、全角:2)
  var regexp = /[\x01-\x7E\u{FF65}-\u{FF9F}]/mu;
  
  var len = 0;
  for(i = 0; i < text.length; i++){
    var ch = text[i];
    len += regexp.test(new String(ch)) ? 1 : 2;
  }
  return len;
}

function clearQuotationDetail(quotation_detail_id) {
  // 金額小計などの入力欄をクリアする(明細書が削除されたとき)
  // auotation_detail_id : 変更があった見積明細書に共通の id
  //                      (quotation_quotation_details_attributes_X)
  // console.log('[clearQuotationDetail] detail_id = %o', quotation_detail_id);

  $('#' + quotation_detail_id + "_タイトル").val("");
  $('#' + quotation_detail_id + "_金額小計").val(0);
  $('#' + quotation_detail_id + "_仕入小計").val(0);
  $('#' + quotation_detail_id + "_提供額小計").val(0);
  $('#' + quotation_detail_id + "_粗利益").val(0);

}

function clearQuotationContent(content_id) {
  // 金額など全ての入力欄をクリアする(見積行が削除されたとき)
  // content_id : 変更があった見積内容(行)に共通の id
  //              (qutation_quotation_details_attributes_X_quotation_contents_attributes_Y)
  // console.log('[clearQuotationContent] content_id = %o', content_id);
  
  var content_type_id   = content_id + "_content_type";
  var product_name_id   = content_id + "_品名";
  var ref1_id           = content_id + "_備考";
  var purchase_check_id = content_id + "_仕入チェック";
  var purchase_code_id  = content_id + "_仕入先コード";
  var ref2_id           = content_id + "_備考2";

  var quantity_id       = content_id + "_数量";
  var unit_id           = content_id + "_単位";
  var unit_price_id     = content_id + "_単価";
  var total_price_id    = content_id + "_金額";
  var purchase_unit_id  = content_id + "_仕入単価";
  var purchase_total_id = content_id + "_仕入額";
  var sales_unit_id     = content_id + "_提供単価";
  var sales_total_id    = content_id + "_提供額";

  $('#' + content_type_id).val(-1);
  $('#' + product_name_id).val("");
  $('#' + ref1_id).val("");
  $('#' + purchase_check_id).prop("checked", false);
  $('#' + purchase_code_id).val(0);
  $('#' + ref2_id).val("");

  $('#' + quantity_id).val(0);
  $('#' + unit_id).val("");
  $('#' + unit_price_id).val(0);
  $('#' + total_price_id).val(0);
  $('#' + purchase_unit_id).val(0);
  $('#' + purchase_total_id).val(0);
  $('#' + sales_unit_id).val(0);
  $('#' + sales_total_id).val(0);
}

// function enableDisableQuotationContent(content_id, self_id) {
//   // 見積内容のコメントチェック、削除チェックの状態に応じて、他の要素の有効/無効などを変更する
//   // content_id : 変更があった見積内容(行)に共通の id
//   //              (qutation_quotation_details_attributes_X_quotation_contents_attributes_Y)
//   // self_id    : 変更があった見積内容(行)の要素
//   //               (qutation_quotation_details_attributes_X_quotation_contents_attributes_Y_コメントチェック)
//   //               (qutation_quotation_details_attributes_X_quotation_contents_attributes_Y__destroy)
//   // console.log('[enableDisableQuotationContent] content_id = %o, self_id = %o', content_id, self_id);

//   var quantity_id       = content_id + "_数量";
//   var unit_id           = content_id + "_単位";
//   var unit_price_id     = content_id + "_単価";
//   var total_price_id    = content_id + "_金額";
//   var purchase_check_id = content_id + "_仕入チェック";
//   var purchase_code_id  = content_id + "_仕入先コード";
//   var purchase_unit_id  = content_id + "_仕入単価";
//   var purchase_total_id = content_id + "_仕入額";
//   var sales_unit_id     = content_id + "_提供単価";
//   var sales_total_id    = content_id + "_提供額";
//   var ref2_id           = content_id + "_備考2";
//   var product_name_id   = content_id + "_品名";
//   var ref1_id           = content_id + "_備考";

//   if ($("#" + self_id).prop("checked")) {
//     $('#' + quantity_id).val(0);
//     $('#' + quantity_id).addClass("readonly");
//     $('#' + quantity_id).prop("readonly", true);

//     $('#' + unit_id).val("");
//     $('#' + unit_id).addClass("readonly");
//     $('#' + unit_id).prop("readonly", true);

//     $('#' + unit_price_id).val(0);
//     $('#' + unit_price_id).addClass("readonly");
//     $('#' + unit_price_id).prop("readonly", true);

//     $('#' + total_price_id).val(0);

//     $('#' + purchase_check_id).prop("checked", false);
//     $('#' + purchase_check_id).css("pointer-events", "none");

//     $('#' + purchase_code_id).val(0);
//     $('#' + purchase_code_id).css("pointer-events", "none");

//     $('#' + purchase_unit_id).val(0);
//     $('#' + purchase_unit_id).addClass("readonly");
//     $('#' + purchase_unit_id).prop("readonly", true);

//     $('#' + purchase_total_id).val(0);

//     $('#' + sales_unit_id).val(0);
//     $('#' + sales_unit_id).addClass("readonly");
//     $('#' + sales_unit_id).prop("readonly", true);

//     $('#' + sales_total_id).val(0);

//     $('#' + ref2_id).val("");
//     $('#' + ref2_id).addClass("readonly");
//     $('#' + ref2_id).prop("readonly", true);

//     if (self_id.match(/__destroy/)) {
//       $('#' + product_name_id).val("");
//       $('#' + product_name_id).addClass("readonly");
//       $('#' + product_name_id).prop("readonly", true);

//       $('#' + ref1_id).val("");
//       $('#' + ref1_id).addClass("readonly");
//       $('#' + ref1_id).prop("readonly", true);
//     }
//   }
//   else {
//     $('#' + quantity_id).removeClass("readonly");
//     $('#' + quantity_id).prop("readonly", false);

//     $('#' + unit_id).removeClass("readonly");
//     $('#' + unit_id).prop("readonly", false);

//     $('#' + unit_price_id).removeClass("readonly");
//     $('#' + unit_price_id).prop("readonly", false);

//     $('#' + purchase_check_id).css("pointer-events", "auto");

//     $('#' + purchase_code_id).css("pointer-events", "auto");

//     $('#' + purchase_unit_id).removeClass("readonly");
//     $('#' + purchase_unit_id).prop("readonly", false);

//     $('#' + sales_unit_id).removeClass("readonly");  
//     $('#' + sales_unit_id).prop("readonly", false);

//     $('#' + ref2_id).removeClass("readonly");  
//     $('#' + ref2_id).prop("readonly", false);

//     if (self_id.match(/__destory/)) {
//       $('#' + product_name_id).removeClass("readonly");  
//       $('#' + product_name_id).prop("readonly", false);  

//       $('#' + ref1_id).removeClass("readonly");  
//       $('#' + ref1_id).prop("readonly", false);  
//     }
//   }
// }

function calcQuotationDetailPrice(quotation_content_id) {
  // 見積明細書の金額を計算する
  // quotation_content_id : 変更があった見積内容(行)の id
  // console.log('[calcQuotationDetailPrice] quotation_content_id = %o', quotation_content_id);

  // 見積明細書の id の共通部分(quotation_quotation_details_attributes_xxxx)を取り出す
  var quotation_detail_id
    = quotation_content_id.slice(0, quotation_content_id.indexOf("quotation_contents_attributes"));

  var detail_price_subtotal = 0, detail_purchase_subtotal = 0;
  var detail_sales_subtotal = 0;

  $('[id$="金額"]').each(function () {    // id が「金額」を含み、
    // 今計算しようとしている見積明細書に属して、
    if ($(this).attr("id").match(quotation_detail_id)) {
     // その見積内容(行)がコメント行でなければ、
      if (!($('#' + $(this).attr("id").replace("金額","コメントチェック")).prop("checked"))) {
        // 見積明細書の金額小計に、見積内容の金額を加算する
        detail_price_subtotal += Number($(this).val());
      }
    }
  });
  $('[id$="仕入額"]').each(function () {
    if ($(this).attr("id").match(quotation_detail_id)) {
      if (!($('#' + $(this).attr("id").replace("仕入額","コメントチェック")).prop("checked"))) {
        detail_purchase_subtotal += Number($(this).val());
      }
    }
  });
  $('[id$="提供額"]').each(function () {
    if ($(this).attr("id").match(quotation_detail_id)) {
      if (!($('#' + $(this).attr("id").replace("提供額","コメントチェック")).prop("checked"))) {
        detail_sales_subtotal += Number($(this).val());
      }
    }
  });
  // console.log('detail_sales_subtotal = %o', detail_sales_subtotal);

  $('#' + quotation_detail_id + "金額小計").val(detail_price_subtotal);
  $('#' + quotation_detail_id + "仕入小計").val(detail_purchase_subtotal);
  $('#' + quotation_detail_id + "提供額小計").val(detail_sales_subtotal);
  $('#' + quotation_detail_id + "粗利益").val(detail_sales_subtotal - detail_purchase_subtotal);

  // 自分が表紙でなければ、表紙にある自分の要約行を更新
  var my_quotation_detail_title_id = quotation_detail_id + "見積明細書タイトル";
  var my_quotation_detail_title = $('#' + my_quotation_detail_title_id).val();
  var my_quotation_detail_code_id = quotation_detail_id + "見積明細書コード";
  var my_quotation_detail_code = $('#' + my_quotation_detail_code_id).val();
  if ((my_quotation_detail_title != "御見積書") && (my_quotation_detail_title != "表紙")
    && (my_quotation_detail_code != 0)) {
    var toppage_summary_id = searchTopPageSummaryContent(my_quotation_detail_title_id);
    // console.log('[calcQuotationDetailPrice] toppage_summary_id = %o', toppage_summary_id);
    if (toppage_summary_id != "") {
      setTopPageSummaryContent(toppage_summary_id, quotation_detail_id.slice(0, quotation_detail_id.length -1));
    }
  }

  // 見積書全体の金額の再計算
  calcQuotationPrice();
};

function calcQuotationPrice() {
  // 見積書の金額を計算する
  // console.log('[calcQuotationPrice]');

  var sum_price = 0, maintenance_price = 0;
  var tax_rate = Number($('#quotation_tax').val());

  $('[id$="金額小計"]').each(function () {
    var my_id = $(this).attr("id");
    var my_detail_id = my_id.slice(0, my_id.indexOf("_金額小計"));
    var my_title_id = my_detail_id + "_見積明細書タイトル";
    var my_code_id = my_detail_id + "_見積明細書コード";
    // 表紙(タイトルが御見積書または表紙、または、見積書コードが0)のときは小計に加算
    if (($('#' + my_title_id).val().match(/見積書/)) || ($('#' + my_title_id).val().match(/表紙/))
        || ($('#' + my_code_id).val() == 0)) {
      sum_price += Number($(this).val());
    }
    // 非計上チェック ON の場合は月額保守費用に加算
    // else if ($('#' + $(this).attr("id").replace("金額小計","非計上チェック")).prop("checked")) {
    //   maintenance_price += Number($(this).val());
    // }
  });
  $('#quotation_小計').val(sum_price);
  $('#quotation_消費税').val(Math.floor(sum_price * tax_rate));
  $('#quotation_合計').val(sum_price + Number($('#quotation_消費税').val()));

  // 参考リース料金：小計 x リース料率 を100円単位で切り上げ
  var lease_fee;
  lease_fee = $('#quotation_小計').val() * $('#quotation_リース料率').val(); // 小計 x リース料率
  console.log("start: %o", lease_fee);
  // javascript の計算では誤差が生じる
    // 10,000 * 0.14 = 1400.0000000000002 などになる => そのまま計算すると本来1400円が1500円になってしまう
  // リース料率は小数点以下 4桁まで入力できるので、小数点以下5桁目以降は javascript の計算誤差として無視する
  lease_fee = Math.floor(lease_fee * 10000); // 10000 掛けて、小数点以下を切り捨てる
  console.log("x10000 and floor: %o", lease_fee);
  lease_fee = lease_fee / 10000; // 10000 で割って、元の桁数に戻す
  console.log("/10000: %o", lease_fee);
  // リース料金は 100円単位に切り上げ
  lease_fee = Math.ceil(lease_fee / 100); // 100 で割って、小数点以下を切り上げる
  console.log("/100 and ceil: %o", lease_fee);
  lease_fee = lease_fee * 100; // 100 を掛けて、元の桁数に戻す
  console.log("x100 last: %o", lease_fee);

  if ($('#quotation_税込区分').val() == "税込") {    // 税込区分が 0(税込) なら
    $('#quotation_価格').val($('#quotation_合計').val());
    // $('#quotation_月額保守料金').val(Math.floor(maintenance_price * (1 + tax_rate) / 12));
    $('#quotation_参考リース料金').val(Math.floor(lease_fee * (1 + tax_rate)));
  }
  else {    // 税込区分が 税別 なら
    $('#quotation_価格').val($('#quotation_小計').val());
    // $('#quotation_月額保守料金').val(Math.floor(maintenance_price / 12));
    $('#quotation_参考リース料金').val(lease_fee);
  };
};

function checkDiscount(self_id) {
  // 値引き行なら値引き金額を再計算
  // self_id : 変更があった品名の id

  var content_type_id = self_id.replace("_品名", "_content_type");
  var content_type = $('#' + content_type_id).val();
  // console.log('[checkDiscount] content_type = %o', content_type);
  if (content_type != 1) {
    return;
  }

    var quotation_detail_id
      = self_id.slice(0, self_id.indexOf("quotation_contents_attributes"));
    var my_quantity_id   = self_id.replace("品名", "数量");
    var my_unitprice_id  = self_id.replace("品名", "単価");
    var my_price_id = self_id.replace("品名", "金額");
    var my_sales_id = self_id.replace("品名", "提供額");
    var price_subtotal = 0, sales_subtotal = 0;
    var price_id, sales_id;

    $('[id$="金額"]').each(function () {
      price_id = $(this).attr("id");
      if ((price_id.match(quotation_detail_id)) && (price_id != my_price_id)) {
        if (!($('#' + $(this).attr("id").replace("金額","コメントチェック")).prop("checked"))) {
          price_subtotal += Number($(this).val());
        }
      }
    });
    $('[id$="提供額"]').each(function () {
      sales_id = $(this).attr("id");
      if ((sales_id.match(quotation_detail_id)) && (sales_id != my_sales_id)) {
        if (!($('#' + $(this).attr("id").replace("提供額","コメントチェック")).prop("checked"))) {
          sales_subtotal += Number($(this).val());
        }
      }
    });
    $("#" + my_quantity_id).val(Number(-1));
    $("#" + my_unitprice_id).val(price_subtotal - sales_subtotal);
    $("#" + my_price_id).val(sales_subtotal - price_subtotal);
    calcQuotationDetailPrice(self_id);
}

function insertQuotation(added_obj) {
  // 新しい見積明細書または見積内容(行)が追加されたときの処理
  // added_obj: 追加されたオブジェクト
  // console.log('[cocoon:after-insert] insertQuotation');

  var added_class = $(added_obj).attr("class");
  // console.log('added_obj = %o, added_obj.class = %o', added_obj, added_class);
  // 追加されたものが見積明細書の場合
  if (added_class == "quotation_detail_class") {
    insertQuotationDetail(added_obj);
  }
  // 追加されたものが見積内容(行)の場合
  else if (added_class == "quotation_content_class") {
    insertQuotationContent(added_obj);
  };
};

function insertQuotationDetail(added_obj) {
  // 新しい見積明細書が追加されたとき：見積明細書番号決定、タブの追加、タブの切替え
  // added_obj : 追加されたオブジェクト
  // console.log('[insertQuotationDetail]');

  // 追加された見積明細書の中の「_見積明細書コード」オブジェクトを取得
  var $new_linenum = $(added_obj).find($('[id$="_見積明細書コード"]'));
  // console.log('new_linenum = %o', $new_linenum);
  // 兄弟(同じ見積書内の見積明細書の「_見積明細書コード」オブジェクトを取得
  var $brothers = $(added_obj).siblings().find($('[id$="_見積明細書コード"]'));
  // 兄弟がいない(その見積書の最初の見積明細書なら、明細書コードは 0 にする
  if ($brothers.length == 0) { // 通常は最初に作っているので該当しない
    // console.log('no brothers => new_detailnum.val = 0')
    $new_linelnum.val(0);
  }
  // 兄弟がいれば見積明細書コードの最大値を探す
  else {
    var max_linenum = 0;
    $brothers.each(function () {
      var this_linenum = Number($(this).val());
      // console.log('brother : %o, .val = %o', $(this), this_linenum);
      if (this_linenum > max_linenum) {
        max_linenum = this_linenum;
        // console.log('max_linenum <= %o updated', max_linenum);
      }
    });
    // 新しい見積明細書の見積明細書コードを、同じ見積書内の見積明細書コードの最大値+1に設定する
    $new_linenum.val(Number(max_linenum) + 1);
    // console.log('max_linenum = %o, new_linenum.val = %o',
      // max_linenum, $new_linenum.val());
  }

  //// タブ処理
  // (1) 自身のタブ用の id を設定
  var $my_div = added_obj.children("div");
  var my_div_id = $my_div.attr("id");
  var new_id = "details_" + (Number(max_linenum) + 1);
  // console.log('my_div = %o, id = %o => new_id = %o', $my_div, my_div_id, new_id);
  $my_div.attr("id", new_id);
  // console.log('my_div new_id = %o', $my_div.attr("id"));

  // (2) caption を設定
  var $my_caption = added_obj.find("caption");
  $my_caption.text("見積明細書 ( " + (Number(max_linenum) + 2) + " ページ )");
  // console.log('my_caption => %o', $my_caption.val());

  // (3) タブを追加
  var $tab_ul = $("ul.tab");
  // // console.log('ul = %o', $tab_ul);
  var new_target = "#" + new_id;
  // // console.log('new_target = %o', new_target);
  var new_li = '<li><a href=' + new_target + '>' +
    '<i class="fas fa-list fa-fw"></i>' + "(新しい明細書)" + '</a></li>';
  // console.log('new_li = %o', new_li);    
  $tab_ul.append(new_li);

  // (4) active なタブを、新しく作成したタブに変更
  var hashIDName = new_target;
  // console.log('hashIDName = %o', hashIDName);
  $('.tab li').find('a').each(function() {
    var idName = $(this).attr('href');
    // console.log('[GethashID] idName = %o', idName);
    if (idName == hashIDName) {
      // console.log('[GethashID] match %o', hashIDName);
      var parentElm = $(this).parent();
      $('.tab li').removeClass("active");
      $(parentElm).addClass("active");
      $('.tab-area').removeClass("is-active");
      $(hashIDName).addClass("is-active");
    }
  });
  return false;
};

function insertQuotationContent(added_obj) {
  // 新しい見積内容(行)が追加されたとき：値引き計算、行番号決定など
  // added_obj : 追加されたオブジェクト
  // console.log('[insertQuotationContent]');

  // 追加された見積内容(行)(tr)の兄弟の「行追加リンク(下側)」を一番下に移動する
  var $bottom_links = $(added_obj).parent().find(".bottom_links");
  $bottom_links.insertAfter($(added_obj));

  // 追加された見積内容(行)(tr)の中の「_行番号」オブジェクトを取得
  var $new_linenum = $(added_obj).find($('[id$="_行番号"]'));
  var $new_content_type = $(added_obj).find($('[id$="_content_type"]'));
  // console.log('new_linenum = %o', $new_linenum);
  // console.log('new_content_type = %o', $new_content_type.val());
  // content_type の値が 1 なら、値引き行なので値引き額の計算を行って処理終了
  if ($new_content_type.val() == 1) {
    var new_name_id = $(added_obj).find($('[id$="_品名"]')).attr("id");
    // console.log('new_content_type.val = 1, checkDiscount(%o) and return', new_name_id);
    checkDiscount(new_name_id);
    // var $my_div = $(added_obj).closest("div");
    // $my_div.find('#quotation_add_discount_button').each(function() {
    //   // console.log('#quotation_add_discount_button %o => disabled', $(this));
    //   $(this).prop("disabled", true);
    // });
    return;
  }
  // content_type の値が 2 なら、次ページ以降の見積明細書の要約行
  else if ($new_content_type.val() == 2) {
    addSummaryContentToDetail($new_linenum);
  }
  // 兄弟(同じ見積明細書内の明細内容(行))の「_行番号」オブジェクトを取得
  var $brothers = $(added_obj).siblings().find($('[id$="_行番号"]'));
  // 兄弟がいない(その見積明細書の最初の見積内容(行))なら、行番号は 0 にする
  if ($brothers.length == 0) {
    // console.log('no brothers => new_linenum.val = 0')
    $new_linenum.val(0);
  }
  // 兄弟がいれば行番号の最大値を探す(値引き行 content_type=1 を除く)
  else {
    var max_linenum = 0;
    $brothers.each(function () {
      var this_linenum = Number($(this).val());
      // console.log('brother : %o, .val = %o', $(this), this_linenum);
      var this_id = $(this).attr("id");
      var this_content_type_id = this_id.replace("_行番号", "_content_type");
      var this_content_type_val = $('#' + this_content_type_id).val();
      if ((this_linenum > max_linenum) && (this_content_type_val != 1)) {
        max_linenum = this_linenum;
        // console.log('max_linenum <= %o updated', max_linenum);
      }
      else if (this_content_type_val == 1) {
        // 値引き行が既にあれば、最後に移動する
        $parent_tr = $(this).parents(".quotation_content_class");
        $next_tr = $parent_tr.next("tr");
        if ($next_tr.length) {
          $parent_tr.insertAfter($next_tr);
        }
      }
    });
    // 新しい見積内容(行)の行番号を、同じ見積明細書内の行番号の最大値+1に設定する
    $new_linenum.val(Number(max_linenum) + 1);
    // console.log('max_linenum = %o, new_linenum.val = %o',
      // max_linenum, $new_linenum.val());
  }
};

function addSummaryContentToDetail($new_linenum) {
  // 見積明細書の要約行が追加されたときの処理: 品名の決定、金額の更新
  // $new_linenum : 追加された要約行の行番号オブジェクト
  // console.log('[addSummaryContentToDetail] $new_linenum = %o', $new_linenum);

  var $my_product_name = $new_linenum.parent("td").parent("tr").find("[id$='_品名']");
  // この見積明細書内にある見積内容の品名を集める
  var $my_quotation_detail = $new_linenum.parents("#content-association-insertion-point");
  // console.log('$my_quotation_detail = %o', $my_quotation_detail);
  var $quotation_contents = $my_quotation_detail.find(".quotation_content_class");
  // console.log('$quotation_contents = %o', $quotation_contents);
  var $product_names =  $quotation_contents.find("[id$='_品名']");
  // console.log('$product_name = %o', $product_names);
  var product_name_array = [];
  $product_names.each(function() {
    product_name_array.push($(this).val());
  })
  // console.log('product_name_array = %o', product_name_array);

  // 他の見積明細書のタイトルを集める
  var $roots_of_quotation = $new_linenum.parents("ul#detail-association-insertion-point");
  // console.log('$roots = %o', $roots_of_quotation);
  var $quotation_details = $roots_of_quotation.find("div.tab-area");
  // console.log('$details = %o', $quotation_details);
  var $detail_numbers = $quotation_details.find("[id$='_見積明細書コード']");
  // console.log('$numbers = %o', $detail_numbers);
  var target_detail_title_id;
  var target_detail_id = "";   // 対象となる見積明細書
  var matched = false;
  $detail_numbers.each(function () {
    var $detail_title = $(this).parent("td").parent("tr").find("[id$='_見積明細書タイトル']");
    // console.log('no = %o, title = %o', $(this).val(), $detail_title.val());
    var destroy_id = $detail_title.attr("id").replace("_見積明細書タイトル", "__destroy");
    // どれを採用するか決める (削除チェック付きとタイトルなしは除外)
    if (($('#' + destroy_id).prop("checked") == false) && ($(this).val() != 0)) {
      if (!product_name_array.includes($detail_title.val())) {
        // console.log('!!this!! %o', $detail_title.val());
        $my_product_name.val($detail_title.val());
        target_detail_title_id = $detail_title.attr("id");
        target_detail_id = target_detail_title_id.slice(0, target_detail_title_id.indexOf("_見積明細書タイトル"));
        // console.log('target_detail_id = %o', target_detail_id);
        matched = true;
        return false;
      }
    }
  });
  if (!matched) {
    return;
  }

  // 編集枠を無効化
  var new_linenum_id = $new_linenum.attr("id");
  var content_id = new_linenum_id.slice(0, new_linenum_id.indexOf("_行番号"));
  disableQuotationContentOnSummary(content_id);

  // 金額関連の代入
  setTopPageSummaryContent(content_id, target_detail_id);
}

function disableQuotationContentOnSummary(content_id) {
  // 要約行の要素の有効/無効などを変更する
  // content_type_id : 変更があった見積内容(行)の共通 id
  //                   (qutation_quotation_details_attributes_X_quotation_contents_attributes_Y)
  // console.log('[disableQuotationContentOnSummary] content_id = %o', content_id);

  // 要約行は各要素を読み取り専用にする(画面を開いたときの初期設定としても利用)
  var content_type_id   = content_id + "_content_type";
  var isSummary = false;
  if ($('#' + content_type_id).val() == 2) {
    // console.log('[disableQuotationContentOnSummary] summary!');
    isSummary = true;
  }

  var quantity_id       = content_id + "_数量";
  var unit_id           = content_id + "_単位";
  var unit_price_id     = content_id + "_単価";
  var purchase_check_id = content_id + "_仕入チェック";
  var purchase_code_id  = content_id + "_仕入先コード";
  var purchase_unit_id  = content_id + "_仕入単価";
  var sales_unit_id     = content_id + "_提供単価";
  var ref2_id           = content_id + "_備考2";

  if (isSummary) {
    $('#' + quantity_id).addClass("readonly");
    $('#' + quantity_id).prop("readonly", true);

    $('#' + unit_id).addClass("readonly");
    $('#' + unit_id).prop("readonly", true);

    $('#' + unit_price_id).addClass("readonly");
    $('#' + unit_price_id).prop("readonly", true);

    $('#' + purchase_check_id).prop("checked", false);
    $('#' + purchase_check_id).css("pointer-events", "none");

    $('#' + purchase_code_id).css("pointer-events", "none");

    $('#' + purchase_unit_id).addClass("readonly");
    $('#' + purchase_unit_id).prop("readonly", true);

    $('#' + sales_unit_id).addClass("readonly");
    $('#' + sales_unit_id).prop("readonly", true);

    $('#' + ref2_id).addClass("readonly");
    $('#' + ref2_id).prop("readonly", true);
  }
}

function searchTopPageSummaryContent(quotation_detail_title_id) {
  // 表紙の要約行を探す
  // quotation_detail_title_id : 対象の見積明細書タイトルの id
  // return (toppage_summary_id) : 見つけた要約行の id の共通部分 (なければ "")
  // console.log('[searchTopPageSummaryContent] quotation_detail_title_id = %o', quotation_detail_title_id);

  var my_quotation_detail_title = $('#' + quotation_detail_title_id).val();
  var my_quotation_detail_previous_title = $('#' + quotation_detail_title_id).data("previous_val");
  // console.log('title = %o, previous_title = %o', my_quotation_detail_title, my_quotation_detail_previous_title);
  
  var toppage_title_id = "";
  var toppage_id = "";
  var toppage_summary_name_id = "";
  // 表紙を探す
  $('[id$="見積明細書タイトル"]').each(function () {
    var my_title = $(this).val();
    var my_code_id = $(this).attr("id").replace("_見積明細書タイトル", "_見積明細書コード");
    var my_code = $('#' + my_code_id).val();
    if ((my_title == "御見積書") || (my_title == "表紙") || (my_code == 0)) {
      toppage_title_id = $(this).attr("id");
      toppage_id = toppage_title_id.slice(0, toppage_title_id.indexOf("_見積明細書タイトル"));
      return false;
    }
  });
  // console.log('[searchTopPageSummaryContent] toppage_id = %o', toppage_id);
  // 要約行を探す
  if (toppage_id != "") {
    $('[id$="品名"]').each(function () {
          // 表紙にある品名だけ対象
      if (($(this).attr("id").match(toppage_id))
          // 現在のタイトル名または変更前のタイトル名で検索
          && (($(this).val() != "")
          && (($(this).val() == my_quotation_detail_title)
              || ($(this).val() == my_quotation_detail_previous_title)))) {
            toppage_summary_name_id = $(this).attr("id");
          return false;
      }
    });
  }
  if (toppage_summary_name_id != "") {
    return toppage_summary_name_id.slice(0, toppage_summary_name_id.indexOf("_品名"));
  }
  else {
    return "";
  }
}

function clearTopPageSummaryContent(my_content_id, target_detail_id) {
  // 表紙のページにある、次ページ以降の見積明細書の要約行を削除する
  // my_coutent_id    : 表紙の見積行に共通の id (quotation_quotation_detail_attributes_0_quotation_contents_attributes_X)
  // target_detail_id : 対象となる見積明細書に共通の id (quotation_quotation_detail_attributes_Y)
  // console.log('[clearTopPageSummaryContent] my_content_id = %o, target_detail_id = %o', my_content_id, target_detail_id);

  // 金額などをクリア
  clearQuotationContent(my_content_id);
  // 削除チェックを入れる(保存されないように)
  var destroy_id = my_content_id + '__destroy';
  $('#' + destroy_id).prop("checked", true);
  // 非表示にする
  var $content_table = $('#' + destroy_id).closest("table"); // 見積内容のtable
  var $content_tr = $content_table.closest("tr");  // 見積内容の table を含む、見積明細書 table の tr
  // console.log('[clearTopPageSummaryContent] $content_tr = %o', $content_tr)
  $content_tr.hide();
}

function setTopPageSummaryContent(my_content_id, target_detail_id) {
  // 表紙のページにある、次ページ以降の見積明細書の要約行を更新する
  // my_coutent_id    : 表紙の見積行に共通の id (quotation_quotation_detail_attributes_0_quotation_contents_attributes_X)
  // target_detail_id : 対象となる見積明細書に共通の id (quotation_quotation_detail_attributes_Y)
  // console.log('[setTopPageSummaryContent] my_content_id = %o, target_detail_id = %o', my_content_id, target_detail_id);

  var my_quantity_id       = my_content_id + "_数量";
  var my_unit_id           = my_content_id + "_単位";
  var my_unit_price_id     = my_content_id + "_単価";
  var my_total_price_id    = my_content_id + "_金額";
  var my_purchase_unit_id  = my_content_id + "_仕入単価";
  var my_purchase_total_id = my_content_id + "_仕入額";
  var my_sales_unit_id     = my_content_id + "_提供単価";
  var my_sales_total_id    = my_content_id + "_提供額";
  
  var target_subtotal_id          = target_detail_id + "_金額小計";
  var target_purchase_subtotal_id = target_detail_id + "_仕入小計";
  var target_sales_subtotal_id    = target_detail_id + "_提供額小計";
  var target_not_sumup_id         = target_detail_id + "_非計上チェック";
  // console.log('[setTopPageSummaryContent] target_not_sumup_id = %o', target_not_sumup_id);

  $('#' + my_quantity_id).val(Number(1));
  $('#' + my_unit_id).val("式");
  if (!($('#' + target_not_sumup_id).prop("checked"))) {
    // console.log('target_subtotal = %o', $('#' + target_subtotal_id).val());
    $('#' + my_unit_price_id).val($('#' + target_subtotal_id).val());
    $('#' + my_total_price_id).val($('#' + target_subtotal_id).val());
    // console.log('target_purchase_subtotal = %o', $('#' + target_purchase_subtotal_id).val());
    $('#' + my_purchase_unit_id).val($('#' + target_purchase_subtotal_id).val());
    $('#' + my_purchase_total_id).val($('#' + target_purchase_subtotal_id).val());
    // console.log('target_sales_subtotal = %o', $('#' + target_sales_subtotal_id).val());
    $('#' + my_sales_unit_id).val($('#' + target_sales_subtotal_id).val());
    $('#' + my_sales_total_id).val($('#' + target_sales_subtotal_id).val());
  }
  else {
    // console.log('not_sumup -> clear');
    $('#' + my_unit_price_id).val(0);
    $('#' + my_total_price_id).val(0);
    $('#' + my_purchase_unit_id).val(0);
    $('#' + my_purchase_total_id).val(0);
    $('#' + my_sales_unit_id).val(0);
    $('#' + my_sales_total_id).val(0);
  }

  // 表紙にある値引行の再計算
  var quotation_detail_id
    = my_content_id.slice(0, my_content_id.indexOf("quotation_contents_attributes"));
  $('[id$="_品名"]').each(function () {
    // 同じ見積明細書の範囲内で
    if ($(this).attr("id").match(quotation_detail_id)) {
      checkDiscount($(this).attr("id"));
    }
  });

  // 見積明細書の金額を計算
  calcQuotationDetailPrice(my_content_id);
}

function reviseTabName(changed_title) {
  // 見積明細書タイトルが更新されたときにタブ名を変更する
  // changed_title : 変更された見積明細書タイトルのオブジェクト
  // console.log('[reviseTabName] changed_title = %o', changed_title);

  // 見積明細書の明細書タイトルを取得
  // console.log('previous = %o, new = %o', $(changed_title).data("previous_val"), $(changed_title).val());
  var new_title = $(changed_title).val();
  // 文字数をチェック
  var title_length = textLength($(changed_title).val());
  // console.log("title_length = %o", title_length);
  if (title_length > 36) {
    alert("タイトルは全角18文字(半角36文字)以内で入力してください。\n現在、半角換算で " + title_length + " 文字入力されています。")
    $(changed_title).focus();
    $(changed_title).get(0).setSelectionRange(title_length, title_length);
    return;
  };
  // 見積明細書が含まれる <div class="tab-area"> の id を取得
  var $target_div = $(changed_title).parents("div.tab-area");
  var target_id = "#" + $target_div.attr("id");
  // console.log('[reviseTabName] div = %o, id = %o', $target_div, target_id);
  // タブの中で、同じ id を指す <a> を探す
  $('.tab li').find('a').each(function () {
    var idName = $(this).attr('href');
    // // console.log('[reviseTabName] idName = %o', idName);
    if (idName == target_id) {
      // console.log('[reviseTabName] matched_this', $(this));
      // タブの文字列を変更する
      $(this).text(new_title);
      // console.log('[reviseTabName] tab => %o', $(this).text());
    }
  });

  // 表紙に自分の見積明細書の要約行があるか検索
  var toppage_summary_id = searchTopPageSummaryContent($(changed_title).attr("id"));
  if (toppage_summary_id != "") {
    // あれば、表紙の要約行の品名を変更する
    $('#' + toppage_summary_id + "_品名").val(new_title);
  }
};

function summaryContentsMinusDetails() {
  // 「見積書表紙の要約行の数 - 表紙を除く明細書の数」を返す関数

  // 表紙の見積明細書内にある見積内容のうち、要約行の数を数える
  var $top_detail = $("#details_0");
  var $top_page_contents = $top_detail.find("[id$='_content_type']");
  // console.log('[summaryContensMinusDetails] number of content_types = %o', $top_page_contents.length);
  var number_of_summaries = 0;
  $top_page_contents.each(function(){
    // console.log('[summaryContensMinusDetails] content_type(%o) = %o', $(this).attr("id"), $(this).val());
    if ($(this).val() == "2") {
      number_of_summaries = number_of_summaries + 1;
    }
  });
  // console.log('[summaryContensMinusDetails] number_of_summaries = %o', number_of_summaries);

  // 明細書の数を数える
  var number_of_details = 0;
  var destroy_id;
  $('[id$="__destroy"]').each(function () {
    destroy_id = $(this).attr("id");
    // 見積明細書の削除チェックであり (見積内容の削除チェックではなく)
    if (!(destroy_id.match(/_quotation_contents_attributes/))) {
      // console.log('[summaryContentsMinusDetails] destroy_id = %o = %o',
        // destroy_id, $('#' + destroy_id).prop("checked"))
      // 削除チェック off なら数える
      if ($('#' + destroy_id).prop("checked") == false) {
        number_of_details = number_of_details + 1;
      }
    }
  })
  // console.log('[summaryContensMinusDetails] number_of_details = %o', number_of_details);

  // 「要約行の数 - 明細書数」を返す
  return (number_of_summaries - number_of_details);
}
