// 保守契約書の編集画面(maintenance_contracts/_form.htm.erb)
//   その他、設置場所関連(shared/_form_location.html.erb)

$(document).on("turbolinks:load", function(){

  // 最初に画面が開いた時の処理
  $(function(){
    contractTermSelect();
    contractLocationSelect();
    contractPicCheck();
    contractPicSelect();
    contractPaymentDateCheck();
  });

  // 各 form 部品の値が変わった時の処理
  $('#contractTermSelect').on("change", contractTermSelect);  // 契約期間が変わった時
  $('#contractStartDate').on("change", contractTermSelect);   // 契約開始日が変わった時
  function contractTermSelect(){  // 契約満了日を計算して disable する。あるいは単に enable する
    if ($('#contractTermSelect').val() != "") {  // 契約期間が選ばれている場合
      if ($('#contractStartDate').val() != "") {  // 契約開始日が入っている場合
        // alert($('#contractStartDate').val() + ": " + $('#contractTermSelect').val());
        var endDate = new Date($('#contractStartDate').val()); // 契約開始日の "2021-06-23" 形式から Date オブジェクト作成
        switch ($('#contractTermSelect').val()) {  // 契約期間の選択
          case '1年間':
            endDate.setFullYear(endDate.getFullYear() + 1);
            endDate.setDate(endDate.getDate() - 1);
            break;
          case '6か月':
            endDate.setMonth(endDate.getMonth() + 6);
            endDate.setDate(endDate.getDate() - 1);
            break;
          case '3か月':
            endDate.setMonth(endDate.getMonth() + 3);
            endDate.setDate(endDate.getDate() - 1);
            break;
          case '1か月':
            endDate.setMonth(endDate.getMonth() + 1);
            endDate.setDate(endDate.getDate() - 1);
            break;
        }
        // alert(endDate);
        var monthStr = endDate.getMonth() + 1; // javascript の月は 0..11 なので 1 加える
        if (monthStr < 10) {
          monthStr = '0' + monthStr;
        }
        var dateStr = endDate.getDate();
        if (dateStr < 10) {
          dateStr = '0' + dateStr;
        }
        var endDateStr = endDate.getFullYear() + "-" + monthStr + "-" + dateStr // "yyyy-mm-dd" 形式に戻す
        // alert("result:" + endDateStr);
        $('#contractEndDate').val(endDateStr); // date_filed に値を計算した終了日を入れる
      } // 契約開始日が入っていない場合は、単に無効にするだけ
      $('#contractEndDate').prop("disabled", true); // 契約満了日の date_field を無効にする
    }
    else {  // 契約期間の選択が非選択になったら
      $('#contractEndDate').prop("disabled", false); // 契約満了日の date_field を有効に戻す
    }
  }

  $('#contractLocationSelect').on("change", contractLocationSelect);
  function contractLocationSelect(){
    // alert('#contractLocationSelect' + ": " + $('#contractLocationSelect').val());
    if ($('#contractLocationSelect').val() != 0) {
      $('#contractLocationText').prop("disabled", true);
    }
    else {
      $('#contractLocationText').prop("disabled", false);
    }
  }

  $('#contractPicCheck').on("change", contractPicCheck);
  function contractPicCheck(){
    // alert('#contractPicCheck' + ": " + $('#contractPicCheck').prop('checked'));
    if (!$('#contractPicCheck').prop('checked')) {
      $('#contractPicSelect').prop("disabled", true);
      $('#contractPicTitle').prop("disabled", true);
      $('#contractPicName').prop("disabled", true);
      $('#contractPicTitle2').prop("disabled", true);
      $('#contractPicName2').prop("disabled", true);
    }
    else {
      $('#contractPicSelect').prop("disabled", false);
      // $('#contractPicTitle').prop("disabled", false);
      // $('#contractPicName').prop("disabled", false);
      // $('#contractPicTitle2').prop("disabled", false);
      // $('#contractPicName2').prop("disabled", false);
      contractPicSelect();
    }
  }

  $('#contractPicSelect').on("change", contractPicSelect);
  function contractPicSelect(){
    // alert('#contractPicSelect' + ": " + $('#contractPicSelect').val());
    if ($('#contractPicSelect').val() != 0) {
      $('#contractPicTitle').prop("disabled", true);
      $('#contractPicName').prop("disabled", true);
      $('#contractPicTitle2').prop("disabled", true);
      $('#contractPicName2').prop("disabled", true);
    }
    else {
      $('#contractPicTitle').prop("disabled", false);
      $('#contractPicName').prop("disabled", false);
      $('#contractPicTitle2').prop("disabled", false);
      $('#contractPicName2').prop("disabled", false);
    }
  }

  $('#contractPaymentDateCheck').on("change", contractPaymentDateCheck);
  function contractPaymentDateCheck(){
    // alert('#contractPaymentDateCheck' + ": " + $('#contractPaymentDateCheck').prop('checked'));
    if (!$('#contractPaymentDateCheck').prop('checked')) {
      $('#contractPaymentDeadline').prop("disabled", true);
      $('#contractPaymentDate').prop("disabled", true);
    }
    else {
      $('#contractPaymentDeadline').prop("disabled", false);
      $('#contractPaymentDate').prop("disabled", false);
    }
  }
});
