$(document).on("turbolinks:load", function () {

  $(function () {
    $('#customer_name_search').on('click', function () {
      var customer_name = $.trim($('#customer_name_field').val());
      var customer_tel  = $.trim($('#customer_tel_field').val());
      var customer_addr = $.trim($('#customer_addr_field').val());
      // console.log('[search customer] customer_name = %o', customer_name);
      // console.log('[search customer] customer_tel = %o',  customer_tel);
      // console.log('[search customer] customer_addr = %o', customer_addr);

      $.ajax({
        type: 'GET',
        url: '/customers/search',
        data: { customer_name: customer_name, customer_tel: customer_tel, customer_addr: customer_addr },
        dataType: 'json'
      })
        .done(function (customer_candidates) {
          // console.log(customer_candidates);
          var result_length = $(customer_candidates).length
          var result_length_str;
          $('.js-customers tr').remove(); // 現在の結果表示を消す
          if (result_length >= 100) {
            result_length_str = "検索結果: 100件以上 (" + result_length + "件)あります。さらに絞り込んでください";
            // console.log(result_length_str);
            $('#result_length').text(result_length_str);
          }
          else {
            result_length_str = "検索結果: " + result_length + "件。得意先を選択して編集タブへ戻ってください。";
            // console.log(result_length_str);
            $('#result_length').text(result_length_str);
            $(customer_candidates).each(function (i, customer) {
              // console.log('customer = %o', customer);
              $('.js-customers').append(
                `<tr><td><input type="radio" name="customer_sel" class="customer"
                  value=${customer.顧客ID} id=${customer.顧客ID}></td>
                  <td>${customer.顧客ID}</td>
                  <td>${customer.顧客名1}</td>
                  <td>${customer.顧客名2}</td>
                  <td>${customer.住所1}</td>
                  <td>${customer.住所2}</td>
                  <td>${customer.TEL}</td>
                  <input type="hidden" id="customer_name_1_${customer.顧客ID}" value="${customer.顧客名1}"> 
                  <input type="hidden" id="customer_name_2_${customer.顧客ID}" value="${customer.顧客名2}"> 
                  </tr>`
              );
            });
          };
        });
    });
  });
});

$(document).on("change", "input[name='customer_sel']", function () { 
  var customer_code = $("input[name='customer_sel']:checked").val();
  // console.log('[search_customer slected] customer_id = %o', customer_code);
  // $('#selected_customer').text("選択された得意先コード: " + customer_code);
  $('#quotation_得意先コード').val(customer_code);
  $('#dailyreport_得意先コード').val(customer_code);
  $('#journal_顧客ID').val(customer_code);

  var customer_name_1 = $('input[id="customer_name_1_' + customer_code + '"]').val();
  var customer_name_2 = $('input[id="customer_name_2_' + customer_code + '"]').val();
  // console.log('[search_customer slected] customer_name_1 = %o', customer_name_1);
  // console.log('[search_customer slected] customer_name_2 = %o', customer_name_2);
  $('#customer_name_1').text(customer_name_1 + " (" + customer_code + ")");
  $('#customer_name_1').removeClass("field_with_errors");
  $('#customer_name_2').text(customer_name_2);
  $('#quotation_表示得意先名1').val(customer_name_1);
  $('#quotation_表示得意先名2').val(customer_name_2);
  $('#dailyreport_表示得意先名1').val(customer_name_1);
  $('#dailyreport_表示得意先名2').val(customer_name_2);
  $('#dailyreport_担当者コード').children().attr("selected", false);
  $('#dailyreport_pic_name').val("");
  $('#dailyreport_pic_input_1').hide();
  $('#journal_表示得意先名1').val(customer_name_1);
  $('#journal_表示得意先名2').val(customer_name_2);
  $('#journal_先方担当ID').children().attr("selected", false);
  $('#journal_先方担当者名').val("");
  // $('#dailyreport_pic_input_1').hide(); // common id to dailyreport
  $('#header_customer_name').text(customer_name_1 + " " + customer_name_2);

  // 右上の得意先の保守、見積、日報リンクボタンを隠す
  $('#header_customer_links').hide();

  // 商談商品、修理機械コンボボックスをクリアする
  $("#combo_syoudan").val("");
  $("#combo_syuri").val("");
  
  $.ajax({
    type: 'GET',
    url: '/dounyus/search',
    data: { customer_code: customer_code },
    dataType: 'json'
  })
    .done(function (dounyu_candidates) {
        $('#combo_syoudan').children().remove();
        $('#combo_syoudan').append($('<option>').html("選択してください"));
        $('#combo_syuri').children().remove();
        $('#combo_syuri').append($('<option>').html("選択してください"));
        $(dounyu_candidates).each(function (i, dounyu) {
          var tmp_str
          if(dounyu.商品名){tmp_str=dounyu.商品名}
          if(dounyu.機番){tmp_str=tmp_str+'['+dounyu.機番+']'}
          $("#combo_syoudan").append('<option value='+dounyu.導入ID.toString()+'>'+tmp_str+'</option>')
          $("#combo_syuri").append('<option value='+dounyu.導入ID.toString()+'>'+tmp_str+'</option>')

        });
    });
});
