$(document).on("turbolinks:load", function () {

  section_val = $('#q_所属ID_eq').val();
  // console.log('[categories] initial section = %o', section_val);
  if (section_val != "" && section_val != "0") {
    $selectedTemplate = $(`#company_members_${section_val}`);
    // console.log('[categories] selected members = %o', $selectedTemplate);
    $('#q_社員ID_eq').remove();
    $('#selected_company_member').remove();
    $('#member_insertion_point').after($selectedTemplate.html());
  }
  else {
    // $('#member_insertion_point').after(defaultMemberSelect);
  };

  $(document).on("change", "#q_所属ID_eq", function () {
    section_val = $('#q_所属ID_eq').val();
    // console.log('[categories] selected section = %o', section_val);
    if (section_val != "" && section_val != "0") {
      $selectedTemplate = $(`#company_members_${section_val}`);
      // console.log('[categories] selected members = %o', $selectedTemplate);
      $('#q_社員ID_eq').remove();
      $('#selected_company_member').remove();
      $('#member_insertion_point').after($selectedTemplate.html());
    }
    else {
      $('#selected_company_member').remove();
      $('#q_社員ID_eq').remove();
      $defaultTemplate = $(`#company_members_system`);
      $('#member_insertion_point').after($defaultTemplate.html());
    };
  });

});
